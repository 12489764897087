.mainContainerHowToInstall {
    background: linear-gradient(140deg, rgba(51, 0, 102, 1) 0%, rgba(221, 61, 77, 1) 50%, rgba(237, 112, 46, 1) 100%), url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/pattern_how_to_install.webp"),
    url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/soldier_elements1_HowToInstall.webp"), url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/soldier_elements2_HowToInstall.webp");
    background-repeat: no-repeat;
    background-blend-mode: color;
    background-size: cover, cover, 35%, 20%;
    background-position: top, top, left bottom, right top;
    color: white;
    padding: 18px 0px 4.6vw;
    font-family: var(--font-primary);
}

.headingParent {
    display: flex;
    justify-content: center;
}

.heading {
    font-size: clamp(16px, 3vw, 60px);
    font-weight: 900;
    font-style: italic;
    letter-spacing: 2.25px;
    max-width: 65vw;
    text-align: center;
    margin-top: 10px;
}


.sliderContainer {
    padding: clamp(20px, 5.2vw, 100px) 10px;
}


.imageParent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.imageContainer {
    position: relative;
    height: clamp(278px, 26vw, 556px);
    width: clamp(157px, 14.68vw, 314px);
    border-radius: 10px;
    box-shadow: 0 10px 5px 0 rgba(0, 0, 0, 0.26);
    border: 2px solid #fff;
    overflow: hidden;
}


.howToInstallContent {
    text-align: center;
    padding: 20px clamp(12px, 2.6vw, 50px);
    font-size: clamp(12px, 0.9vw, 18px);
    font-weight: 500;
    font-style: italic;
    color: #fff;
}

.howToInstallContent .step {
    display: none;
}

@media screen and (max-width: 767px) {

    .mainContainerHowToInstall {
        background: linear-gradient(141deg, rgba(80, 16, 93, 1) 0%, rgba(240, 66, 44, 1) 100%), url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/soldier_elements1_HowToInstall.webp");
        background-repeat: no-repeat;
        background-size: cover, 50%;
        background-position: left bottom;
        padding: 7px 0px 4.6vw;
    }

    .heading {
        letter-spacing: 1px;
        margin-bottom: 10px;
    }

    .howToInstallItem {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
    }

    .howToInstallContent {
        line-height: 1.25;
    }

    .howToInstallContent .step {
        display: block;
        font-weight: 600;
        letter-spacing: 0.43px;
    }

    .howToInstallContent .desc {
        padding: 10px 0px;
        border-top: 2px solid white;
        border-bottom: 2px solid white;
    }

    .imageParent {
        width: 100%;
    }

    .imageContainer {
        box-shadow: 0 9px 13px 0 rgba(0, 0, 0, 0.15);
        border: 1px solid #ffffff;
    }
}
